import {Route} from '@angular/router';
import {NAV} from '@gigasoftware/evolving-cognition/domain';
import {ecAuthGuardGuard} from './ec-auth-guard.guard';

export const appRoutes: Route[] = [
  // {
  //   path: 'login',
  //   pathMatch: 'full',
  //   loadChildren: () =>
  //     import('@gigasoftware/evolving-cognition/ui').then(
  //       m => m.LOGIN_PAGE_ROUTES
  //     )
  // },
  {
    path: 'home',
    loadChildren: () => import('./pages/ec-home/home-tiles/ec-home.routes').then(m => m.EC_HOME_MODULE_ROUTES)
  },
  {
    path: 'privacy-policy',
    pathMatch: 'full',
    loadChildren: () => import('@gigasoftware/evolving-cognition/ui').then(m => m.EC_POLICY_ROUTES)
  },
  {
    path: 'terms-of-use',
    pathMatch: 'full',
    loadChildren: () => import('@gigasoftware/evolving-cognition/ui').then(m => m.EC_TERMS_OF_USE_ROUTES)
  },
  {
    path: 'mobile',
    // canActivate: [AuthGuardService],
    children: [
      // ADMIN ADMIN ADMIN ADMIN ADMIN ADMIN
      // ADMIN ADMIN ADMIN ADMIN ADMIN ADMIN
      // ADMIN ADMIN ADMIN ADMIN ADMIN ADMIN

      {
        path: 'admin-subjects',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/admin/ec-subjects/ec-subjects.routes').then(m => m.EC_SUBJECTS_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },

      {
        path: 'admin-users',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/admin/ec-admin-users/ec-admin-users.routes').then(m => m.EC_ADMIN_USERS_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },

      // PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES
      // PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES
      // PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES PERSONAL QUIZZES

      {
        path: 'add-personal-note',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/personal/ec-personal-note/ec-personal-note.routes').then(m => m.EC_PERSONAL_NOTE_ROUTES)
      },

      {
        path: 'personal-notes',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/personal/ec-personal-notes/ec-personal-notes.routes').then(m => m.EC_PERSONAL_NOTES_ROUTES)
      },

      {
        path: 'quiz-create',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/personal/ec-quiz-create/ec-quiz-create.routes').then(m => m.EC_QUIZ_KIT_CREATE_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'ec-quiz-list',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/personal/ec-quiz-list-page/ec-quiz-list-page.routes').then(m => m.EC_QUIZ_LIST_PAGE_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'quiz-test',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/personal/ec-quiz-test/ec-quiz-test-page.routes').then(m => m.EC_QUIZ_KIT_TEST_PAGE_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'quiz-stats',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/personal/ec-quiz-stats/ec-quiz-stats-page.routes').then(m => m.EC_QUIZ_STATS_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'quiz-edit',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/personal/ec-quiz-edit/ec-my-quiz-edit-page.routes').then(m => m.EC_MY_QUIZ_EDIT_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'quiz-grades',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/personal/ec-quiz-grades/quiz-grade-page.routes').then(m => m.EC_QUIZ_GRADE_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'quiz-question-create',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/personal/ec-quiz-question-create/ec-quiz-question-create.routes').then(
            m => m.EC_QUIZ_QUESTION_CREATE_ROUTES
          ),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'quiz-question-edit',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/personal/ec-quiz-question-edit/ec-quiz-question-edit.routes').then(m => m.EC_QUIZ_QUESTION_EDIT_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'quiz-review',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/personal/ec-quiz-review/ec-quiz-review-page.routes').then(m => m.EC_QUIZ_REVIEW_PAGE_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },

      // STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS
      // STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS
      // STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS STUDY GROUPS

      {
        path: 'study-group-note',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-note/ec-study-group-note.routes').then(m => m.EC_STUDY_GROUP_NOTE_ROUTES)
      },

      {
        path: 'study-group-quiz-edit',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-quiz-edit/study-group.routes').then(m => m.EC_STUDY_GROUP_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'study-group-create',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-create/ec-study-group-create.routes').then(m => m.EC_STUDY_GROUP_ROUTES),
        canActivate: [ecAuthGuardGuard]
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },
      {
        path: 'study-group-list',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-list/ec-study-group-list.routes').then(m => m.EC_STUDY_GROUP_LIST_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'study-group-item',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-item/ec-study-group-item.routes').then(m => m.EC_STUDY_GROUP_ITEM_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },

      {
        path: 'study-group-quiz-grades',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-quiz-grades/ec-study-group-grade.routes').then(
            m => m.EC_STUDY_GROUP_GRADE_ROUTES
          ),
        canActivate: [ecAuthGuardGuard]
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },
      // {
      //   path: 'study-group-all-grades',
      //   pathMatch: 'full',
      //   loadChildren: () =>
      //     import(
      //       './pages/mobile/study-group-all-grades/study-group-all-grades.routes'
      //     ).then(m => m.STUDY_GROUP_ALL_GRADES_ROUTES)
      //   // canActivate: [AuthGuardService, StudyGroupsGuardService]
      // },
      {
        path: 'study-group-quiz-stats',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-quiz-stats/study-group-quiz-item.routes').then(
            m => m.EC_STUDY_GROUP_QUIZ_STATS_ROUTES
          ),
        canActivate: [ecAuthGuardGuard]
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },
      {
        path: 'study-group-quiz-item-test',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-quiz-item-test/ec-study-group-quiz-item-test.routes').then(
            m => m.EC_STUDY_GROUP_QUIZ_ITEM_ROUTES
          ),
        canActivate: [ecAuthGuardGuard]
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },
      {
        path: 'study-group-quiz-create',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-quiz-create/study-group-quiz-create.routes').then(
            m => m.EC_STUDY_GROUP_QUIZ_CREATE_ROUTES
          ),
        canActivate: [ecAuthGuardGuard]
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },
      {
        path: 'study-group-edit',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-edit/ec-study-group-edit.routes').then(m => m.EC_STUDY_GROUP_EDIT_ROUTES),
        canActivate: [ecAuthGuardGuard]
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },
      {
        path: 'study-group-quiz-review',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-quiz-review/study-group-quiz-review-page.routes').then(
            m => m.EC_STUDY_GROUP_QUIZ_REVIEW_ROUTES
          )
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },
      {
        path: 'study-group-quiz-question-create',
        pathMatch: 'full',
        loadChildren: () =>
          import(
            './pages/small/study-groups/ec-study-group-quiz-question-create-page/ec-study-group-quiz-question-create-page.routes'
          ).then(m => m.EC_STUDY_GROUP_QUIZ_QUESTION_CREATE_ROUTES),
        canActivate: [ecAuthGuardGuard]
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },
      {
        path: 'study-group-quiz-question-edit',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/study-groups/ec-study-group-quiz-question-edit-page/study-group-quiz-question-edit-page.routes').then(
            m => m.EC_STUDY_GROUP_QUIZ_QUESTION_EDIT_PAGE_ROUTES
          ),
        canActivate: [ecAuthGuardGuard]
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },

      // CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES
      // CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES
      // CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES CLASSES

      {
        path: 'class-note',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/classes/ec-class-note/ec-class-note.routes').then(m => m.EC_CLASS_NOTE_ROUTES)
      },

      {
        path: 'class-create',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/classes/ec-class-create-page/ec-class-create.routes').then(m => m.EC_CLASS_CREATE_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'class-list',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/classes/ec-class-list-page/ec-class-list-page.routes').then(m => m.EC_CLASS_LIST_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'class-item',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/classes/ec-class-item/ec-class-item.routes').then(m => m.EC_CLASS_ITEM_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'class-quiz-edit',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/classes/ec-class-quiz-edit-page/ec-class-quiz-edit.routes').then(m => m.EC_CLASS_QUIZ_EDIT_ROUTES),
        canActivate: [ecAuthGuardGuard]
        // canActivate: [AuthGuardService, StudyGroupsGuardService]
      },
      {
        path: 'class-quiz-student-grades',
        pathMatch: 'full',
        data: {
          navID: NAV.MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE
        },
        loadChildren: () =>
          import('./pages/small/classes/ec-class-quiz-student-grades/ec-class-quiz-student-grades.routing').then(
            m => m.EC_MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE_ROUTE
          ),
        canActivate: [ecAuthGuardGuard]
      },
      // {
      //   path: 'class-all-grades',
      //   pathMatch: 'full',
      //   data: {
      //     navID: NAV.MOBILE_CLASS_ALL_GRADES_PAGE
      //   },
      //   loadChildren: () =>
      //     import(
      //       './pages/mobile/class-all-grades/class-quiz-all-grades.routes'
      //     ).then(m => m.MOBILE_CLASS_ALL_GRADES_PAGE_ROUTE)
      // },
      {
        path: 'class-quiz-stats',
        pathMatch: 'full',
        data: {
          navID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE
        },
        loadChildren: () =>
          import('./pages/small/classes/ec-class-quiz-stats/ec-class-quiz-stats.routes').then(m => m.EC_CLASS_QUIZ_STATS_PAGE_ROUTE),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'class-quiz-test',
        pathMatch: 'full',
        data: {
          navID: NAV.MOBILE_CLASS_QUIZ_TEST_PAGE
        },
        loadChildren: () =>
          import('./pages/small/classes/ec-class-quiz-test-page/ec-class-quiz-test.routes').then(m => m.EC_CLASS_QUIZ_TEST_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'class-quiz-create',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/classes/ec-class-quiz-create/ec-class-quiz-create.routes').then(m => m.EC_CLASS_QUIZ_CREATE_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'class-edit',
        pathMatch: 'full',
        loadChildren: () => import('./pages/small/classes/ec-class-edit/ec-class-edit.routes').then(m => m.EC_CLASS_EDIT_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'class-quiz-review',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/small/classes/ec-class-quiz-review-page/ec-class-quiz-review.routes').then(m => m.EC_CLASS_QUIZ_REVIEW_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },

      {
        path: 'class-quiz-question-create',
        loadChildren: () =>
          import('./pages/small/classes/ec-class-quiz-question-create-page/ec-class-quiz-question-create.routes').then(
            m => m.EC_CLASS_QUIZ_QUESTION_CREATE_ROUTES
          ),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'class-quiz-question-edit',
        loadChildren: () =>
          import('./pages/small/classes/ec-class-quiz-question-edit-page/ec-class-quiz-question-edit-page.routes').then(
            m => m.EC_CLASS_QUIZ_QUESTION_EDIT_ROUTES
          ),
        canActivate: [ecAuthGuardGuard]
      },

      // APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP
      // APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP
      // APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP

      {
        path: 'profile',
        loadChildren: () => import('@gigasoftware/evolving-cognition/ui').then(m => m.EC_PROFILE_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },

      {
        path: 'account',
        pathMatch: 'full',
        loadChildren: () => import('@gigasoftware/evolving-cognition/ui').then(m => m.SHARED_ACCOUNT_ROUTES),
        canActivate: [ecAuthGuardGuard]
      },
      {
        path: 'subscribe',
        pathMatch: 'full',
        loadChildren: () => import('@gigasoftware/evolving-cognition/ui').then(m => m.EC_IN_APP_SUBSCRIBE_ROUTES)
      },
      {
        path: 'support',
        pathMatch: 'full',
        loadChildren: () => import('@gigasoftware/evolving-cognition/ui').then(m => m.EC_SUPPORT_ROUTES)
      }
      // {path: '', redirectTo: 'home', pathMatch: 'full'},
      // {path: '**', redirectTo: 'home', pathMatch: 'full'}
    ]
  },

  // DESKTOP
  // {
  //   path: 'dashboard',
  //   canActivate: [AuthGuardService],
  //   children: [
  //     /**
  //      * Home page determined at:
  //      * libs/web-platform/data-access/src/lib/+ui/ui.selectors.ts:80
  //      */
  //     {
  //       path: 'classes',
  //       loadChildren: () =>
  //         import(
  //           './pages/desktop/dashboard-classes/dashboard-classes.routes'
  //         ).then(m => m.DASHBOARD_CLASSES_ROUTES)
  //     },
  //
  //     {
  //       path: 'class',
  //       loadChildren: () =>
  //         import('./pages/desktop/dashboard-class/dashboard-class.routes').then(
  //           m => m.DASHBOARD_CLASS_ROUTES
  //         )
  //     },
  //
  //     {
  //       path: 'personal-quizzes',
  //       loadChildren: () =>
  //         import(
  //           './pages/desktop/dashboard-personal-quizzes/dashboard-personal-quizzes.routes'
  //         ).then(m => m.DASHBOARD_PERSONAL_QUIZZES)
  //     },
  //
  //     {
  //       path: 'study-groups',
  //       loadChildren: () =>
  //         import(
  //           './pages/desktop/dashboard-study-groups/dashboard-study-groups.routes'
  //         ).then(m => m.DASHBOARD_STUDY_GROUPS_ROUTES)
  //     },
  //     {
  //       path: 'study-group',
  //       loadChildren: () =>
  //         import(
  //           './pages/desktop/dashboard-study-group/dashboard-study-group.routes'
  //         ).then(m => m.DASHBOARD_STUDY_GROUP_ROUTES)
  //     },
  //
  //     {
  //       path: 'profile',
  //       loadChildren: () =>
  //         import('@gigasoftware/evolving-cognition/ui').then(
  //           m => m.EC_PROFILE_ROUTES
  //         )
  //     },
  //
  //     {
  //       path: 'account',
  //       pathMatch: 'full',
  //       loadChildren: () =>
  //         import('@gigasoftware/evolving-cognition/ui').then(
  //           m => m.SHARED_ACCOUNT_ROUTES
  //         )
  //     },
  //
  //     {
  //       path: 'support',
  //       pathMatch: 'full',
  //       loadChildren: () =>
  //         import('@gigasoftware/evolving-cognition/ui').then(
  //           m => m.QUIZKIT_SUPPORT_ROUTES
  //         )
  //     },
  //     {
  //       path: 'privacy-policy',
  //       pathMatch: 'full',
  //       loadChildren: () =>
  //         import('@gigasoftware/evolving-cognition/ui').then(
  //           m => m.QUIZKIT_POLICY_ROUTES
  //         )
  //     },
  //     {
  //       path: 'terms-of-use',
  //       pathMatch: 'full',
  //       loadChildren: () =>
  //         import('@gigasoftware/evolving-cognition/ui').then(
  //           m => m.QUIZKIT_TERMS_OF_USE_ROUTES
  //         )
  //     },
  //
  //     {path: '', redirectTo: 'classes', pathMatch: 'full'},
  //     {path: '**', redirectTo: 'classes', pathMatch: 'full'}
  //   ]
  // },
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', redirectTo: 'home', pathMatch: 'full'}
];
