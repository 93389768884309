import {MatIconRegistry} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {Component, inject, OnInit, Signal} from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import {RouterModule} from '@angular/router';
import {Capacitor} from '@capacitor/core';
import {SplashScreen} from '@capacitor/splash-screen';
import {
  APP,
  AppIdleComponent,
  AppLoginService,
  DlcWebPricingService
} from '@gigasoftware/shared/ui-design-library';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {
  ACTIVE_STATUS,
  NgPatPresenceService,
  selectIsIOSToolbarContent,
  UiIosNotchCalc
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {distinctUntilChanged} from 'rxjs';
import {DesktopAppHeaderComponent} from './components/desktop/desktop-app-header/desktop-app-header.component';
import {MobileAppHeaderComponent} from './components/mobile/app-header/mobile-app-header.component';

import isLoggedIn$ from './sw-watch';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    DesktopAppHeaderComponent,
    MobileAppHeaderComponent,
    MatDialogModule
  ],
  selector: 'ec-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loginSubscribeService = inject(AppLoginService);
  private webPriceService: DlcWebPricingService = inject(DlcWebPricingService);
  private matIconRegistry = inject(MatIconRegistry);

  // isDesktopWidth: Signal<boolean> = this.store.selectSignal(selectIsDesktop);

  iOSCalc: Signal<UiIosNotchCalc> = this.store.selectSignal(selectIsIOSToolbarContent);

  get isNativePlatform(): boolean {
    return Capacitor.isNativePlatform();
  }

  app: APP = APP.EC;

  constructor(
    private store: Store,
    private presenceService: NgPatPresenceService,
    private customFirestoreService: NgPatFirestoreService,
    private dialog: MatDialog
  ) {
    this.presenceService.init();

    this.presenceService.presence$.pipe(distinctUntilChanged()).subscribe({
      next: (status: ACTIVE_STATUS) => {
        if (status === ACTIVE_STATUS.USER_IDLE || status === ACTIVE_STATUS.NETWORK_OFFLINE) {
          this.dialog.open(AppIdleComponent, {
            data: {
              id: status
            },
            backdropClass: 'dlc-global-dialog-background',
            panelClass: 'mat-typography'
          });
        }
      }
    });

    this.loginSubscribeService.subscribeRoute = ['mobile', 'subscribe'];
  }

  ngOnInit() {
    this.matIconRegistry.setDefaultFontSetClass('material-symbols', 'material-symbols-outlined');

    this.customFirestoreService.isLoggedIn$.subscribe(() => {
      isLoggedIn$.next(true);
    });

    if (!Capacitor.isNativePlatform()) {
      this.webPriceService.loadWebCheckoutScript(this.app);
    }

    SplashScreen.hide();
  }
}
