import {Clipboard} from '@angular/cdk/clipboard';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltip, MatTooltipModule} from '@angular/material/tooltip';
import {EcLogoComponent} from '@gigasoftware/evolving-cognition/ui';
import {
  APP_VERSION,
  getUpgradeVersionString,
  InstalledAppVersion
} from '@gigasoftware/shared/models';
import {
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment
} from '@gigasoftware/shared/store';
import {DlcRoundedIconButtonComponent} from '@gigasoftware/shared/ui-design-library';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    EcLogoComponent,
    CommonModule,
    DlcRoundedIconButtonComponent,
    MatTooltipModule
  ],
  selector: 'ec-about-dialog',
  styleUrls: ['./about-dialog.component.scss'],
  templateUrl: './about-dialog.component.html'
})
export class AboutDialogComponent {
  version = 'Version: ';

  @ViewChild('copyTooltip', {static: true}) copyTooltip!: MatTooltip;

  constructor(
    private _clipboard: Clipboard,
    @Inject(APP_VERSION) public appVersion: InstalledAppVersion,
    @Inject(EC_ENVIRONMENT) private _env: EvolutionCognitionEnvironment
  ) {
    const appVersionCombined = getUpgradeVersionString(this.appVersion.version);

    // Create the version string
    const environment = this._env.environment;
    if (environment !== 'production') {
      this.version += environment + ' ';
    }

    this.version += appVersionCombined;
  }

  copyVersion() {
    this._clipboard.copy(this.version);

    if (this.copyTooltip) {
      this.copyTooltip.show();
    }
  }
}
