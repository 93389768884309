import {NgIf} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {
  AppService,
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment,
  NAV,
  navItemDict,
  PlatSwUpdateService,
  selectCurrentAndPreviousNavItemByCurrentNavId,
  selectLoggedInUserIsEcAdminOrRoot
} from '@gigasoftware/evolving-cognition/domain';
import {EcLogoComponent} from '@gigasoftware/evolving-cognition/ui';
import {NavItem, UrlParamsRouteService} from '@gigasoftware/shared/domain';
import {
  AppLoginService,
  DlcRoundedTextIconButtonComponent,
  NotificationsComponent
} from '@gigasoftware/shared/ui-design-library';
import {
  DEFAULT_NOTIFICATION,
  selectHasNoNotifications,
  selectHasNotifications,
  selectIsIOSToolbarContent,
  selectNgPatIsLoggedIn,
  selectNgPatNotHasActiveSubscription,
  selectNotificationTotal,
  UiIosNotchCalc,
  UiNotification
} from '@ngpat/store';
import {WINDOW, WindowService} from '@ngpat/utils';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';

import {AboutDialogComponent} from '../../about-dialog/about-dialog.component';
import {configureHeaderNav, HeaderNav} from './mobile-app-header.fns';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[style.height.px]': 'iOSCalc().toolbarHeightPx',
    '[style.padding-top.px]': 'iOSCalc().paddingTopPx',
    class: 'ec-mobile-app-header mat-elevation-z2'
  },
  imports: [
    NgIf,
    EcLogoComponent,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    NotificationsComponent,
    MatProgressBarModule,
    DlcRoundedTextIconButtonComponent
  ],
  providers: [AppLoginService],
  selector: 'ec-mobile-app-header',
  styleUrls: ['./mobile-app-header.component.scss'],
  templateUrl: './mobile-app-header.component.html'
})
export class MobileAppHeaderComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  hasNoNotifications: Signal<boolean> = this.store.selectSignal(
    selectHasNoNotifications
  );

  hasNotifications: Signal<boolean> = this.store.selectSignal(
    selectHasNotifications
  );

  iOSCalc: Signal<UiIosNotchCalc> = this.store.selectSignal(
    selectIsIOSToolbarContent
  );

  nav = NAV;

  notHasActiveSubscription: Signal<boolean> = this.store.selectSignal(
    selectNgPatNotHasActiveSubscription
  );

  notificationTotal: Signal<number> = this.store.selectSignal(
    selectNotificationTotal
  );

  parent: WritableSignal<HeaderNav> = signal({
    nav: navItemDict[NAV.MOBILE_HOME_PAGE],
    showLogo: false,
    showNav: false
  });

  selectLoggedInUserIsEcAdminOrRoot: Signal<boolean> = this.store.selectSignal(
    selectLoggedInUserIsEcAdminOrRoot
  );

  constructor(
    // private _router: Router,
    private store: Store,
    public sw: PlatSwUpdateService,
    private _dialog: MatDialog,
    private _winService: WindowService,
    private appAuthService: AppLoginService,
    public urlRouteParams: UrlParamsRouteService,
    public appService: AppService,
    @Inject(WINDOW) private _win: Window,
    @Inject(EC_ENVIRONMENT) private _env: EvolutionCognitionEnvironment
  ) {}

  adminSubjects() {
    this.urlRouteParams.navigateWithParams(NAV.MOBILE_ADMIN_SUBJECTS_PAGE);
  }

  adminUsers() {
    this.urlRouteParams.navigateWithParams(NAV.MOBILE_ADMIN_USERS_PAGE);
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  ngOnInit() {
    this.store
      .select(selectCurrentAndPreviousNavItemByCurrentNavId)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((p: {current: NavItem | null; previous: NavItem | null}) => {
        this.parent.set(configureHeaderNav(p.current, p.previous));
      });
  }

  // onApplePrivacyPolicy() {
  //   this._winService.open(
  //     `https://www.apple.com/legal/internet-services/itunes/dev/stdeula/`
  //   );
  // }

  /**
   * TODO Auto Update
   * @param n
   */
  notificationAction(n: UiNotification) {
    if (n.id === DEFAULT_NOTIFICATION.APP_UPDATE) {
      this.sw
        .checkForUpdate()
        .then(() => {
          console.log('checkForUpdate');
        })
        .catch(err => {
          console.log('checkForUpdate', err);
        });
    }
  }

  onAbout() {
    this._dialog.open(AboutDialogComponent, {
      backdropClass: 'dlc-global-dialog-background'
    });
  }

  onLogin() {
    this.appAuthService.login();
  }

  onLogout() {
    const that = this;
    this.appService.logout();

    this.store
      .select(selectNgPatIsLoggedIn)
      .pipe(
        filter(isLoggedIn => !isLoggedIn),
        take(1)
      )
      .subscribe(() => {
        that._win.location.reload();

        // that._zone.run(() => {
        //   that._router.navigate(['start']).then(() => {
        //     that._win.location.reload();
        //   });
        // });
      });
  }

  onOpenSupport() {
    this._winService.open(`${this._env.deviceBuild.marketingSite}/support`);
  }

  subscribe() {
    this.urlRouteParams.navigateWithParams(NAV.MOBILE_SUBSCRIBE_PAGE);
  }
}
