import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {provideAnimations} from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation
} from '@angular/router';
import {provideServiceWorker} from '@angular/service-worker';
import {Capacitor} from '@capacitor/core';
import {
  EcCopyQuizDialogModule,
  EcPublishQuizModule
} from '@gigasoftware/evolving-cognition/ui';
import {
  ENV_SERVER_ENUM,
  ENTITY_TYPE_BY_ID_DICT,
  firestoreEvolvingCognitionAppVersion
} from '@gigasoftware/shared/api';
import {
  createNgPatFirebaseAppInstance,
  NG_PAT_FIREBASE_INSTANCE
} from '@gigasoftware/shared/firebase';
import {
  APP_VERSION,
  ENV_SERVER,
  ENVIRONMENT,
  InstalledAppVersion,
  LOGOUT_REDIRECT,
  LogoutRedirect,
  ENTITY_TYPE_BY_ID
} from '@gigasoftware/shared/models';
import {NAV_ITEM_DICT, SUBSCRIBE_NAV_ITEM} from '@gigasoftware/shared/store';
import {
  AUTH_URLS,
  EC_ENVIRONMENT,
  EVOLVING_COGNITION_PRODUCTS,
  getNavItem,
  NAV,
  navItemDict,
  SHARED_DATA_ACCESS_PROVIDERS,
  SHARED_NGRX_EFFECTS,
  SHARED_NGRX_INITIALIZERS,
  SHARED_NGRX_REDUCERS,
  WEB_SUBSCRIPTION_PRODUCTS
} from '@gigasoftware/shared/store';
import {
  createIAPConfigProvider,
  IAP_CONFIG,
  IAPConfig,
  NG_PAT_PRESENCE_CONFIG,
  NgPatPresenceService,
  STRIPE_FIRESTORE_PATHS,
  StripeFirestorePathsConfig
} from '@gigasoftware/shared/store';
import {
  NG_PAT_AUTH_PROVIDERS_CONFIG,
  NgPatAppleAuthProviderConfig,
  NgPatAuthProvidersConfig,
  NgPatGoogleAuthProviderConfig
} from '@gigasoftware/shared/ui-design-library';
import {WINDOW_PROVIDERS} from '@gigasoftware/shared/utils';
import {provideEffects} from '@ngrx/effects';
import {provideStore} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';

import {environment} from '../environments/environment';
import {appInstalledBuildVersion} from './app-version';
import {appRoutes} from './app.routes';

const appInstance = createNgPatFirebaseAppInstance(
  environment.firebaseConfigParams
);

// console.log(
//   'register service worker: ',
//   !environment.local && !/android/i.test(window.navigator.userAgent || window.navigator.vendor)
// );

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom([
      MatSnackBarModule,
      EcCopyQuizDialogModule,
      EcPublishQuizModule
    ]),
    provideServiceWorker('ngsw-worker.js', {
      enabled:
        !environment.local &&
        !window.navigator.userAgent.includes('android') &&
        !window.navigator?.vendor.includes('Android'),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideStore(
      {
        ...SHARED_NGRX_REDUCERS
        // [quizkitAppFeatureKey]: quizkitAppReducer
      },
      {
        initialState: {
          ...SHARED_NGRX_INITIALIZERS
          // [quizkitAppFeatureKey]: initialQuizkitAppState
        },
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: true,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateImmutability: true,
          strictStateSerializability: true
        }
      }
    ),
    provideEffects([...SHARED_NGRX_EFFECTS]),
    // providedEffects,
    provideStoreDevtools({
      connectInZone: true,
      logOnly: true,
      maxAge: 25
    }),
    NgPatPresenceService,
    ...WINDOW_PROVIDERS,
    ...SHARED_DATA_ACCESS_PROVIDERS,
    {
      provide: NG_PAT_FIREBASE_INSTANCE,
      useValue: appInstance
    },
    {
      provide: ENV_SERVER,
      useValue: environment.production
        ? ENV_SERVER_ENUM.PROD
        : ENV_SERVER_ENUM.DEV
    },
    {
      provide: STRIPE_FIRESTORE_PATHS,
      useValue: <StripeFirestorePathsConfig>{
        CUSTOMERS: 'users'
      }
    },
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    {
      provide: EC_ENVIRONMENT,
      useValue: environment
    },
    {
      provide: NAV_ITEM_DICT,
      useValue: navItemDict
    },
    {
      provide: SUBSCRIBE_NAV_ITEM,
      useValue: navItemDict[NAV.MOBILE_SUBSCRIBE_PAGE]
    },
    {
      provide: ENTITY_TYPE_BY_ID,
      useValue: ENTITY_TYPE_BY_ID_DICT
    },
    {
      provide: LOGOUT_REDIRECT,
      useValue: <LogoutRedirect>{
        logoutRedirect: getNavItem(NAV.MOBILE_LOGIN_PAGE).navigateTo
      }
    },
    {
      provide: AUTH_URLS,
      useValue: environment.authURLs
    },

    createIAPConfigProvider(<IAPConfig>{
      config: {
        apiKey: '17546b79-d7c4-4ded-852e-d4e234327318', // PUBLIC KEY
        appName:
          Capacitor.getPlatform() === 'ios'
            ? 'com.evolvingcognition.app'
            : 'com.ecog.app',
        // See https://www.iaptic.com/settings
        baseUrl: 'https://validator.iaptic.com'
      },
      iapticAppName: 'com.evolvingcognition.app',
      subscriptionProducts: [...EVOLVING_COGNITION_PRODUCTS],
      webSubscriptionProducts: WEB_SUBSCRIPTION_PRODUCTS
    }),

    {
      provide: APP_VERSION,
      useValue: <InstalledAppVersion>{
        firestorePath: firestoreEvolvingCognitionAppVersion(),
        version: appInstalledBuildVersion
      }
    },
    {
      provide: NG_PAT_PRESENCE_CONFIG,
      useValue: {timerInMinutes: 5}
    },
    {
      provide: NG_PAT_AUTH_PROVIDERS_CONFIG,
      useValue: <NgPatAuthProvidersConfig>{
        apple: <NgPatAppleAuthProviderConfig>{
          scopes: ['email', 'name']
        },
        // email: {
        //   username: false
        // },
        google: <NgPatGoogleAuthProviderConfig>{
          scopes: [
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile'
          ]
        },
        signInWithRedirect: true
      }
    }
  ]
};
